function initMask() {
  [].forEach.call(document.querySelectorAll('.tel'), function (input) {

    function mask() {
      this.value = this.value.replace(/[^\d+-]/g, "");
    }

    input.addEventListener("input", mask, false);
    input.addEventListener("focus", mask, false);
    input.addEventListener("blur", mask, false);
    input.addEventListener("keydown", mask, false)
  });
}

function init() {
  const form = document.getElementById("formElem")
  form?.addEventListener("submit", async (e) => {
    e.preventDefault();

    let response

    try {
      response = await fetch('/', {
        method: 'POST',
        body: new FormData(formElem)
      })
    } finally {
      if (response?.status === 200) {
        const successResponse = document.getElementById("success-response")
        successResponse?.classList.add('visibleResponse');

        setTimeout(() => {
          form.reset()
          successResponse?.classList.remove('visibleResponse');
        }, 5000);
      } else {
        const failedResponse = document.getElementById("failed-response")
        failedResponse?.classList.add('visibleResponse');

        setTimeout(() => {
          failedResponse?.classList.remove('visibleResponse');
        }, 5000);
      }
    }
  });

  const scrollTo = (id) => {
    const element = document.getElementById(id)
    if (!element) return

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  const demoButton = document.getElementById("demo-btn")
  demoButton?.addEventListener("click", () => {
    scrollTo("form")
  })

  const platformButton = document.getElementById("platform-btn")
  platformButton?.addEventListener("click", () => {
    scrollTo("platform")
  })

  const lifeCycleButton = document.getElementById("life-cycle-btn")
  lifeCycleButton?.addEventListener("click", () => {
    scrollTo("life-cycle")
  })

  const advantagesButton = document.getElementById("advantages-btn")
  advantagesButton?.addEventListener("click", () => {
    scrollTo("advantages")
  })

  initMask()
}

window.addEventListener("DOMContentLoaded", init)
